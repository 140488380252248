import React from 'react';

const Header = () => (
  <div className="py-3">
    <img src="https://d2j31icv6dlhz6.cloudfront.net/O/4qrkUigKH2tdn3y1jqaH/agrovista-logo-transparent-rgb.png" alt="Agrovista UK" className="mx-auto d-block p-3 mw-100" />
    <h6 className="text-center subtitle small">Agrovista Amenity Mobile Ordering System</h6>
  </div>
);

export default Header;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import qs from 'qs';
import config from 'utilities/config';
import userActions from '_actions/user.actions';
import blockActions from '_actions/block.actions';
import alertActions from '_actions/alert.actions';

function validateEmail(email) {
  const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRex.test(email);
}

function LoginPage() {
  const dispatch = useDispatch();
  const [emailValid, setEmailValid] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const emailCallback = (event) => setEmailValid(validateEmail(event.target.value));
  const submitForm = (event) => {
    event.preventDefault();
    if (email && password) {
      dispatch(blockActions.block());
      dispatch(userActions.login(email, password));
    }
  };
  useEffect(() => {
    const { expired } = qs.parse(window.location.search.substring(1));
    dispatch(userActions.logout());
    if (expired === '') {
      dispatch(alertActions.error('Your session has expired, please login again.'));
    }
  }, [dispatch]);
  return (
    <div>
      <div className="text-center py-3">
        <img src="https://d2j31icv6dlhz6.cloudfront.net/O/4qrkUigKH2tdn3y1jqaH/agrovista-logo-transparent-rgb.png" alt="Agrovista UK" className="mx-auto d-block p-3 mw-100" />
      </div>
      <Card>
        <CardHeader className="text-center small">{config.name}</CardHeader>
        <CardBody>
          <Form className="form" onSubmit={submitForm}>
            <FormGroup>
              <Label>Email Address:</Label>
              <Input
                type="text"
                name="username"
                required
                value={email}
                valid={emailValid === true}
                invalid={emailValid === false}
                onChange={(event) => {
                  emailCallback(event);
                  setEmail(event.target.value);
                }}
              />
              <FormFeedback>Invalid email address entered</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Password</Label>
              <Input type="password" name="password" required value={password} onChange={(event) => setPassword(event.target.value)} />
            </FormGroup>
            <div className="d-flex">
              <Button type="submit" color="primary" disabled={!(email && password)}>Login</Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}

export default LoginPage;
